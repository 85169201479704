/*jslint browser: true, nomen: true */
/*global $ */
'use strict';

function PageTimer() {
  var self = this;
  this.getLoadTime = function () {
    var now = new Date().getTime();
    // Get the performance object
    window.performance = window.performance || window.mozPerformance || window.msPerformance || window.webkitPerformance || {};
    var timing = performance.timing || {};

    var ms = now - timing.navigationStart;

    return Math.round(ms / 10) / 100;
  };

  this.logToConsole = function () {
    $(window).on('load', function () {
      console && console.info && console.info("Client loaded in " + self.getLoadTime() + 's');
    });
  };

  this.append = function (holder) {
    $(window).on('load', function () {
      holder.text(' | LT: ' + self.getLoadTime() + 's');
    });
  }
}


function StickyFooter($wrap, $footer) {
  var $window = $(window);
  this.updateWrapperCSS = function () {
    var footerHeight = $footer.outerHeight();

    $wrap.css({
      marginBottom: -1 * footerHeight,
      paddingBottom: footerHeight
    });
  };

  this.bindOnResize = function () {
    $window.on('resize', this.updateWrapperCSS);

    return this;
  };

  this.update = function () {
    this.updateWrapperCSS();

    return this;
  }
}


/*
 Ensure all external links load as new window/tabs:
 */


function ExternalLinkHandler() {
  var self = this;
  var hostname = document.location.hostname;

  this.matchInternalLink = [new RegExp("^https?:\/\/(.*?)" + hostname)];

  this.addTargetAttribute = function (context) {
    context.find('a').filter('[href^="http://"], [href^="https://"]').each(function () {
      var anchor = $(this);
      var href = anchor.attr('href');
      var isInternalLink = false;

      for (var i = 0; i < self.matchInternalLink.length; i++) {
        var regex = self.matchInternalLink[i];
        if (href.match(regex)) {
          isInternalLink = true;
        }
      }

      if (!isInternalLink) {
        anchor.attr('target', '_blank').addClass('external-link');
      }
    });
  };

}

function UIBindings() {
  this.bindTooltips = function () {
    $('[data-toggle="tooltip"]').tooltip();
  };

  this.bindStickyNav = function(){
    var nav = $('.sidebar-menu');
    var col = $('#page-body');

    if (nav && col) {
      $(window).scroll(function () {
        if ($(this).scrollTop() > 307) {
          nav.addClass("fixed-sidebar-menu");
          col.addClass("col-sm-push-4 col-md-push-3");
        } else {
          nav.removeClass("fixed-sidebar-menu");
          col.removeClass("col-sm-push-4 col-md-push-3");
        }
      });
    };

    // var pageSlider = $('#page-slider');
    // if (pageSlider) {
    //   $(window).scroll(function () {
    //     console.log(window.innerHeight + window.scrollY);
    //     console.log(window.scrollY - window.innerHeight + document.body.offsetHeight);
    //     console.log(((window.innerHeight + document.body.offsetHeight) - 88));
    //     console.log(document.body.offsetHeight);

    //     console.log(window.scrollY);
    //     if ( window.scrollY > ((window.innerHeight + document.body.offsetHeight))){
    //       pageSlider.addClass("fixed");
    //     }else if( window.scrollY < ((window.innerHeight + document.body.offsetHeight) + 88)){
    //       pageSlider.removeClass("fixed");
    //     }
    //   });
    // };
  };

  this.bindSearchForm = function () {
    $('#search-form').on('submit', function (e) {
      e.preventDefault();
      var searchTerms = $('#search_terms').val();

      window.location.href = "/search#/search?search_terms=" + encodeURIComponent(searchTerms) + "&search_type=tpy_pages&result_size=10&search_offset=0";
    });
  };


  this.bindPopovers = function () {
    $('a[href*="/peopleplacesevents/"]').each(function(){
      var $this = $(this);
        $.ajax({
          url: $(this).attr('href'),
          success: function (resp) {
            $this.data('content', resp);
            var html = $(resp);
            html.addClass('hide ppe');
            $this.after(html);
            
          }
        });
    })
    var options = {
      placement: function (context, source) {
        var $win, $source, winWidth, popoverWidth, popoverHeight, offset, toRight, toLeft, placement, scrollTop;

        $win = $(window);
        $source = $(source);
        placement = $source.attr('data-placement');
        popoverWidth = 400;
        popoverHeight = 400;
        offset = $source.offset();

        // Check for horizontal positioning and try to use it.
        if (placement === 'right' || placement === 'left') {
          winWidth = $win.width();
          toRight = winWidth - offset.left - source.offsetWidth;
          toLeft = offset.left;

          if (placement === 'left') {
            if (toLeft > popoverWidth) {
              return 'left';
            }
            else if (toRight > popoverWidth) {
              return 'right';
            }
          }
          else {
            if (toRight > popoverWidth) {
              return 'right';
            }
            else if (toLeft > popoverWidth) {
              return 'left';
            }
          }
        }

        // Handle vertical positioning.
        scrollTop = $win.scrollTop();
        if (placement === 'bottom') {
          if (($win.height() + scrollTop) - (offset.top + source.offsetHeight) > popoverHeight) {
            return 'bottom';
          }
          return 'top';
        }
        else {
          if (offset.top - scrollTop > popoverHeight) {
            return 'top';
          }
          return 'bottom';
        }
      },
      trigger: 'hover',
      content: PPE,
      html: true
    };
    $('a[href*="/peopleplacesevents/"]').popover(options);
    function PPE(){
      return $(this).find('+ .ppe > .article').html();
    };

    $('a[href*="/information-notes/"]').each(function(){
      var $this = $(this);
        $.ajax({
          url: $(this).attr('href'),
          success: function (resp) {
            $this.data('content', resp);
            var html = $(resp);
            html.addClass('hide info-notes');
            $this.after(html);
            
          }
        });
    })
    var options = {
      placement: function (context, source) {
        var $win, $source, winWidth, popoverWidth, popoverHeight, offset, toRight, toLeft, placement, scrollTop;

        $win = $(window);
        $source = $(source);
        placement = $source.attr('data-placement');
        popoverWidth = 400;
        popoverHeight = 400;
        offset = $source.offset();

        // Check for horizontal positioning and try to use it.
        if (placement === 'right' || placement === 'left') {
          winWidth = $win.width();
          toRight = winWidth - offset.left - source.offsetWidth;
          toLeft = offset.left;

          if (placement === 'left') {
            if (toLeft > popoverWidth) {
              return 'left';
            }
            else if (toRight > popoverWidth) {
              return 'right';
            }
          }
          else {
            if (toRight > popoverWidth) {
              return 'right';
            }
            else if (toLeft > popoverWidth) {
              return 'left';
            }
          }
        }

        // Handle vertical positioning.
        scrollTop = $win.scrollTop();
        if (placement === 'bottom') {
          if (($win.height() + scrollTop) - (offset.top + source.offsetHeight) > popoverHeight) {
            return 'bottom';
          }
          return 'top';
        }
        else {
          if (offset.top - scrollTop > popoverHeight) {
            return 'top';
          }
          return 'bottom';
        }
      },
      trigger: 'hover',
      content: infoNotes,
      html: true
    };
    $('a[href*="/information-notes/"]').popover(options);
    function infoNotes(){
      return $(this).find('+ .info-notes > .article').html();
    };
  };

  this.bindSlickCarousels = function () {
    $('[data-slick-carousel-default]').slick({
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      fade: true
    });

    $('[data-toggle="slick-nav"]').on('click', function (e) {
      e.preventDefault();
      var index = $(this).data('index');
      $('[data-slick-carousel-default]').slick('slickGoTo', index);
    });
  };

  this.bindSharing = function () {
    $("[data-share-default]").each(function () {
      var shareHandler = new ShareHandler($(this));
      shareHandler.appendFacebook();
      shareHandler.appendTwitter();
      shareHandler.appendGoogle();
      //shareHandler.appendLinkedIn();
      //shareHandler.appendPinterest();
    });
  };

  this.bindMagnificpopup = function(){

    $('[data-footnote]').magnificPopup({
         type: 'ajax'
    });
         
    $('[data-magnific-gallery]').magnificPopup({
         type: 'image',
         callbacks:
          {
              markupParse: function(template, values, item)
                  {
                      values.description = item.el.data('description');
                  }
          },
          gallery:{
            enabled:true
          },

          image:
              {
                  headerFit: true,
                  captionFit: true,
                  preserveHeaderAndCaptionWidth: false,
                  markup:
                  '<div class="mfp-figure">'+
                      '<figure>'+
                          '<header class="mfp-header">'+
                              '<div class="mfp-top-bar">'+
                                  '<div class="mfp-title"></div>'+
                                  '<div class="mfp-close"></div>'+
                                  '<div class="mfp-decoration"></div>'+
                              '</div>'+
                          '</header>'+
                          '<section class="mfp-content-container">'+
                              '<div class="mfp-img"></div>'+
                          '</section>'+
                          '<footer class="mfp-footer">'+
                              '<figcaption class="mfp-figcaption">'+
                                  '<div class="mfp-bottom-bar-container">'+
                                      '<div class="mfp-bottom-bar">'+
                                          '<div class="mfp-counter"></div>'+
                                          '<div class="mfp-description"></div>'+
                                          '<div class="mfp-decoration"></div>'+
                                      '</div>'+
                                  '</div>'+
                              '</figcaption>'+
                          '</footer>'+
                      '</figure>'+
                  '</div>',
              }
    });
  };

  this.bindMasonary = function () {
  
    $('[data-masonry-default]').masonry({
      itemSelector: '[data-masonry-default] > div',
      columnWidth: '.grid-sizer'
    });
  };

  this.bindSubmittingButtons = function () {
    $(document).on('submit', function () {
      var submitText = $(this).find('[data-submit-text]');
      var submittingText = $(this).find('[data-submitting-text]');
      var btn = submitText.closest('button');
      submittingText.removeClass('hide');
      submitText.hide();
      btn.prop('disabled', true);
    });
  };

  this.footnoteAppend = function (){
    $(".footnote").each(function() {
      $(this).closest(".matrix-text").find("p").first().append(this);
      $(this).removeClass('hide');
    });
    $("span.last").each(function() {
      $(this).closest(".blockquote").find("p").append(this);
    });
  }

}

function Notifications () {
  var wrapper = $('#notifications-wrapper');
  var div = $('#notifications');
  var wrapperTop = wrapper.offset().top;

  this.bindOnScroll = function () {
    wrapper.height(wrapper.height());
    $(window).on('scroll', function () {
      var scrollTop = $(window).scrollTop();
      if (scrollTop > wrapperTop) {
        div.addClass('fixed');
      } else {
        div.removeClass('fixed');
      }
    })
  };

  this.bindCloseButton = function () {
    $(document).on('click', '[data-toggle="remove"]', function (e) {
      e.preventDefault();
      var notification = $(this).closest('.notification');
      notification.fadeOut(250, function () {
        wrapper.height('auto');
        wrapper.height(wrapper.height());

      });
    });
  };

  this.init = function () {
    this.bindOnScroll();
    this.bindCloseButton();
  };
}

// $(window).scroll(function(){
//   var pageSlider = $('#page-slider');
//   var delta =  $(window).height() - ( pageSlider.offset().top + $('#footer').height() );
  
//   if( $(window).scrollTop() < (delta) ) {
//     pageSlider.addClass("fixed"); 
//   } else if ($(window).scrollTop() == delta){
//     pageSlider.removeClass("fixed");
//   }
// });

$(function () {

  (new StickyFooter($('#container'), $('#footer'))).update().bindOnResize();

  (new ExternalLinkHandler).addTargetAttribute($('body'));

  (new PageTimer).logToConsole();

  (new UIBindings).bindTooltips();

  (new UIBindings).bindStickyNav();

  (new UIBindings).bindSearchForm();

  (new UIBindings).bindPopovers();

  (new UIBindings).bindSlickCarousels();

  (new UIBindings).bindSharing();

  (new UIBindings).footnoteAppend();

  (new UIBindings).bindMasonary();

  (new UIBindings).bindMagnificpopup();

  (new UIBindings).bindSubmittingButtons();

  (new Notifications).init();
});
